import React from "react";
import { NavHashLink } from "react-router-hash-link";
import useFetch from "../../hooks/Usefetch";
import {BlocksRenderer} from '@strapi/blocks-react-renderer';

function Hometwosec() {
  const { data, loading, error } = useFetch("/homepage-image-and-texts?populate=*");
// console.log(data)
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading content...</p>;

  if (!data || data.length === 0) {
    return <p>No content available</p>;
  }

  // const data = [
  //   {
  //     title: "hello",
  //     desc: "description",
  //   },
  //   {
  //     title: "hello",
  //     desc: "description",
  //   }
  // ]

  return (
    <section className="column-1 c-extra">
      <div className="container">
        {data.map((item, index) => {
          const title = item.attributes?.title || "No Title";
          const desc = item.attributes?.desc || "No Description";
          const imgUrl = item.attributes?.img1?.data?.attributes?.url;
          const imageUrl = imgUrl /*? `${process.env.REACT_APP_UPLOAD_URL}${imgUrl}` : "/Assets/images/default.png"*/; 

          return (
            <div key={index} className={`con ${index % 2 === 0 ? 'even' : 'odd'}`}>
              <div className="col-1">
                <h2 className="service-h2" id={index === 0 ? "proplan" : "basicplan"}>
                  {title}
                </h2>
                <p className="service-p">
                  <BlocksRenderer content={desc} />
                </p>
                <NavHashLink to='/contact#form1'>
                  <button className="Btn">Quote Now</button>
                </NavHashLink>
              </div>
              <div className={`col2 ${index % 2 === 0 ? 'right' : 'left'}`}>
                <img src={imageUrl} alt={title} />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default Hometwosec;
