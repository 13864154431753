// ApparelCategory.js
import React from 'react';
import ProductGridLayout from './Basegridlayout';
import useFetch from '../../hooks/Usefetch';

// const products = [
//   {
//     title: "Electric Tools",
//     description: "Our electric tools include small items like angle clamps, wheels, and detectors for testing in different industries.",
//     image: '/Assets/products/industrial1.jpg',
//   },
//   {
//     title: "Equipment",
//     description: "There are different machines like compost turner machines, juice grind machines, etc. They’re available for household or commercial usage.",
//     image: '/Assets/products/industrial2.jpg',
//   },
//   {
//     title: "Storage",
//     description: "For businesses that store their cargo, pallets would be a necessity. Such storage products usually feature in wooden and plastic.",
//     image: '/Assets/products/industrial3.jpg',
//   },
//   {
//     title: "Measurement",
//     description: "There are different measurement tools for calculating the distance, length, angle, speed, etc. They’re available for different industries.",
//     image: '/Assets/products/industrial4.jpg',
//   },
//   {
//     title: "Shipping",
//     description: "These supplies in the warehouse or office are used to ship and organize packages or heavy items, such as pedal-lifting platforms, foldable fences, and so on.",
//     image: '/Assets/products/industrial5.jpg',
//   },
//   {
//     title: "Parts and Components",
//     description: "Except for general tools and machines, parts like warning tapes, plastic tubes, PVC sheets, and wooden pallets are good sellers in the industrial department.",
//     image: '/Assets/products/industrial6.jpg',
//   },
// ];

const Industrial = () => {
  const { data, loading, error } = useFetch('/products?filters[categories][$eq]=Apparel');
  const products = data?.map((item) => ({
    title: item.attributes.title,
    description: item.attributes.description,
    image: `${process.env.REACT_APP_UPLOAD_URL}${item.attributes.image?.data?.attributes?.url || '/Assets/default.jpg'}`,
  })) || [];
  return (
    <ProductGridLayout 
      products={products} 
      title="Industrial Supplies" 
      heroTitle="Explore Our Industrial Supplies" 
      heroImage="/Assets/productbanners/industrialbanner.jpg"
    />
  );
};

export default Industrial;
